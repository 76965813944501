import { Component } from 'react'
import { connect } from 'react-redux'
import { removeSnackbar } from '../store/actions/notificationActions';
import { withSnackbar } from 'notistack';


class Notifier extends Component {
    displayed = [];

    storeDisplayed = (id) => {
        this.displayed = [ ...this.displayed, id ]
    }

    shouldComponentUpdate({ notifications: newSnacks = [] }) {
        const { notifications: currentSnancks } = this.props
        let notExists = false;
        for (let i = 0; i < newSnacks.length; i+=1) {
            if (notExists) continue;
            notExists = notExists || !currentSnancks.filter(({ key }) => newSnacks[i].key === key).length;
        }
        return notExists;
    }

    componentDidUpdate() {
        const { notifications = [] } = this.props

        notifications.forEach((notification) => {
            if (this.displayed.includes(notification.key)) return;
            this.props.enqueueSnackbar(notification.message, notification.options);
            this.storeDisplayed(notification.key);
            this.props.removeSnackbar(notification.key)
        })
    }

    render() {
        return null
    }
}

const mapStateToProps = state => {
    return {
        notifications: state.notifications.notifications
    }
}

const mapDispatchToProps = dispatch => {
    return {
        removeSnackbar: id => dispatch(removeSnackbar(id))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withSnackbar(Notifier))