import React from 'react'
import ReactEcharts from 'echarts-for-react';
import 'echarts-gl/dist/echarts-gl'
import { PRIMARY, SECONDARY } from '../config/config';


function createOption(data, underlying, price, volatility, type) {
    const x = data.map(x => x[0])
    const y = data.map(y => y[1])
    const z = data.map(z => z[2])

    const itemStyleHighlight = {
        color: '#af5e33'
    }

    var beta = -30

    if (type === 'Put') {
        beta = 30
    }


    const itemStyle = {
        color: PRIMARY
      };

    return {
        xAxis3D: {
            name: 'Underlying',
            nameTextStyle: {color: SECONDARY, fontSize: 10},
            type: 'value',
            min: Math.min(...x).toFixed(2),
            max: (Math.round(Math.max(...x)*1.01*100)/100).toFixed(2),
            precision: 2
        },
        yAxis3D: {
            name: 'Volatility',
            nameTextStyle: {color: SECONDARY, fontSize: 10},
            type: 'value',
            min: Math.min(...y).toFixed(2),
            max: (Math.round(Math.max(...y)*1.01*100)/100).toFixed(2),
            precision: 2
        },
        zAxis3D: {
            name: 'Option Price',
            nameTextStyle: {color: SECONDARY, fontSize: 10},
            type: 'value',
            min: 0,
            max: (Math.round(Math.max(...z)*1.01*100)/100).toFixed(2),
            precision: 2
        },
        grid3D: {
            axisLine: {
                lineStyle: { color: SECONDARY }
            },
            axisPointer: {
                lineStyle: { color: PRIMARY }
            },
            viewControl: {
                // autoRotate: true
            },
            light: {
                main: {
                    shadow: true,
                    quality: 'low',
                    intensity: 1.5,
                    beta: beta, // ? type === 'Put' : 30,
                    alpha: 30
                },
            }
        },
        series: [{
            type: 'bar3D',
            bevelSize: 0.3,
            data: data.map(value => {
                if (value[0] === underlying && value[1] === volatility) {
                    return {value: value, itemStyle: itemStyleHighlight}
                } else {
                    return {value: value, itemStyle: itemStyle}
                }
            }),
            shading: 'lambert',
            label: {
                formatter: function (param) {
                    return param.value[2].toFixed(2);
                }
            }
        },
        
    ]
    }
}


class PriceVolatilityMatrix extends React.Component {
    render() {
        const { data, underlyingPrice, optionPrice, underlyingVolatility, type } = this.props
        return (
            <ReactEcharts
                option={createOption(data, underlyingPrice, optionPrice, underlyingVolatility, type)}
                style={{ height:'400px', width:'100%'}}
            />
        )
    }
}

export default PriceVolatilityMatrix