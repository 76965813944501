import { combineReducers } from "redux"
import optionDataReducer from "./optionDataReducer";
import { firebaseReducer, firestoreReducer } from "react-redux-firebase";
import notificationReducer from "./notificationReducer";


const rootReducer = combineReducers({
    optionData: optionDataReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    notifications: notificationReducer
})

export default rootReducer