import React from 'react'
import { Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';
import { SECONDARY, SECONDARY_BACKGROUND, PRIMARY, PRIMARY_BACKGROUND, PRIMARY_BACKGROUND_DARK, SECONDARY_BACKGROUND_DARK } from '../config/config';

function DataTable(props) {

    const { data, underlyingPrice, underlyingVolatility } = props
    const price = [...new Set(data.map(x => x[0]))]
    const volatility = [...new Set(data.map(x => x[1]))]

    return (
        <div style={{ overflowX:'auto'}}>
            <Table padding='dense' >
                <TableHead >
                    <TableRow style={{ height: 22}}>
                        <TableCell style={{ color: SECONDARY, backgroundColor: SECONDARY_BACKGROUND }}>P/V</TableCell>
                        {volatility.map(v => (
                            <TableCell key={v} style={{ color: SECONDARY, backgroundColor: (underlyingVolatility === v ) ? SECONDARY_BACKGROUND_DARK :SECONDARY_BACKGROUND}}>{(Math.round(v*100)/100).toFixed(2)}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {price.map(p => (
                        <TableRow key={p} style={{ height: 22}}>
                            <TableCell style={{ color: SECONDARY, backgroundColor: (underlyingPrice === p ) ? SECONDARY_BACKGROUND_DARK :SECONDARY_BACKGROUND}}>{(Math.round(p*100)/100).toFixed(2)}</TableCell>
                            {volatility.map(v => (
                                <TableCell key={`${p}${v}`} style={{ fontWeight: (underlyingPrice === p && underlyingVolatility === v) ? 'bold' : 'normal', color: PRIMARY, backgroundColor: (underlyingPrice === p && underlyingVolatility === v) ? PRIMARY_BACKGROUND_DARK : PRIMARY_BACKGROUND}}>{(Math.round(data.filter(x => (x[0] === p && x[1] === v))[0][2]*100)/100).toFixed(2)}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

export default DataTable