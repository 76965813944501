import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'

var firebaseConfig = {
    apiKey: "AIzaSyBvI5enWnqJkwXvEir0c6ux3KmJqmtTAvU",
    authDomain: "moving-indicators.firebaseapp.com",
    databaseURL: "https://moving-indicators.firebaseio.com",
    projectId: "moving-indicators",
    storageBucket: "moving-indicators.appspot.com",
    messagingSenderId: "72571243589",
    appId: "1:72571243589:web:eb6537e8bf5747ab"
};

firebase.initializeApp(firebaseConfig)
firebase.firestore()

export default firebase