import companyLogo from "../static/img/logo-optionen-handeln.svg"
import OHLogo from "../static/img/oh-logo.png"

export const PRIMARY = 'rgba(221, 0, 0, 1)'//'rgba(0, 51, 102, 1)'
export const PRIMARY_BACKGROUND = 'rgba(221, 0, 00, 0.1)'//'rgba(0, 51, 102, 0.1)'
export const PRIMARY_BACKGROUND_DARK = 'rgba(221, 0, 0, 0.3)'

export const SECONDARY = 'rgba(0, 0, 0, 1)'//'rgba(124, 193, 65, 1)'
export const SECONDARY_BACKGROUND = 'rgba(0, 0, 0, 0.1)'//'rgba(124, 193, 65, 0.1)'
export const SECONDARY_BACKGROUND_DARK = 'rgba(0, 0, 0, 0.3)'//'rgba(124, 193, 65, 0.1)'



export const COMPANY_LOGO = companyLogo
export const OH_LOGO = OHLogo
