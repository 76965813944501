import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import MainView from './views/MainView';
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './store/reducers/rootReducer';
import thunk from "redux-thunk"
import { Provider } from "react-redux"
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { PRIMARY, SECONDARY } from './config/config';
import { SnackbarProvider } from 'notistack'
import ReactGA from 'react-ga'
import CookieConsentPopUp from './componets/CookieConsentPopUp';
import { reactReduxFirebase, getFirebase } from "react-redux-firebase";
import { reduxFirestore, getFirestore } from "redux-firestore";

import fbConfig from "./config/fbconfig";
import Notifier from './componets/Notifier';

ReactGA.initialize('UA-140771558-1')
ReactGA.pageview('/option_master')

const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
        reactReduxFirebase(fbConfig, {
          userProfile: "users",
          useFirestoreForProfile: true,
          attachAuthIsReady: true
        }),
        reduxFirestore(fbConfig)
    )
)

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
      },
    palette: {
        primary: {
            main: PRIMARY
        },
        secondary: {
            main: SECONDARY
        }
    }
})

store.firebaseAuthIsReady.then(() => {
    ReactDOM.render(
        <MuiThemeProvider theme={theme}>
            <Provider store={store}>
                <SnackbarProvider maxSnack={3}>
                    <MainView />
                    <Notifier />
                </SnackbarProvider>
            </Provider>
        </MuiThemeProvider>,
        document.getElementById('root')

    );
})

serviceWorker.unregister();
