import React, { useState } from "react"
import { Grid, Paper, Typography, Hidden, Tabs, Tab, IconButton, makeStyles } from "@material-ui/core"
import DataForm from "../componets/DataForm";
import DataOutput from "../componets/DataOutput";
import { PRIMARY, COMPANY_LOGO, OH_LOGO } from "../config/config";
import SwipeableViews from 'react-swipeable-views'

import SingOutIcon from '@material-ui/icons/ExitToApp'

const useStyles = makeStyles(() => ({
    signOut: {
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
        float: 'right'
    }
}))
function MainView(props) {

    const [selectedTab, setSelectedTab] = useState(0)

    const { auth, signOut } = props
    const classes = useStyles()

    const tabChanged = (event, value) => {
        setSelectedTab(value)
    }

    const handleChangeIndex = index => {
        selectedTab(index)
    }

    
    const dataOutputCall = <DataOutput type='Call'/>
    const dataOutputPut = <DataOutput type='Put'/>

    return (
        <div style={{
            backgroundColor: PRIMARY,
            flexGrow: 1,
            overflow: 'visible'
        }} >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper style={{ margin: '16px 16px 0px 16px', padding: 16 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={10} container spacing={2}>
                                <Grid item>
                                    <a href="https://optionenhandeln.ch/" target="_blank" rel="noopener" >
                                        <img src={COMPANY_LOGO} alt="" style={{ height: '100px', width: 'auto' }} />
                                    </a>
                                </Grid>
                                <Grid item >
                                    <Typography variant='h5' color='primary' style={{ position: 'relative', top: '50%', WebkitTransform: 'translateY(-50%)', transform: 'translatey(-50%)' }}>
                                        OptionMaster
                                    </Typography>
                                </Grid>
                            </Grid>
                        {/*}    <Grid item xs={2}>
                                <IconButton aria-label="signOut" edge="end" onClick={signOut} className={classes.signOut}>
                                    <SingOutIcon fontSize='inherit' color='primary'/>
                                </IconButton>
                            </Grid> */}
                        </Grid>
                </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{ margin: '0px 16px 0px 16px', padding: 16 }}>
                        <DataForm />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper style={{ margin: '0px 16px 10px 16px', padding: 16 }}>
                        <Hidden smUp>
                            <Grid item xs={12}>
                                <Tabs
                                    value={selectedTab}
                                    onChange={tabChanged}
                                    indicatorColor='primary'
                                    textColor='primary'
                                    centered
                                    style={{ marginTop: -16, paddingBottom: 16 }}
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: PRIMARY
                                        }
                                        }}
                                >
                                    <Tab label="Call" />
                                    <Tab label="Put" />
                                </Tabs>

                            </Grid>
                        </Hidden>
                        <Hidden xsDown>
                            <Grid container spacing={8}>
                                <Grid item xs={12} sm={6}>
                                    {dataOutputCall}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {dataOutputPut}
                                </Grid>
                            </Grid>
                        </Hidden>
                        <Hidden smUp>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <SwipeableViews
                                        //axis='x'
                                        index={selectedTab}
                                        onChangeIndex={handleChangeIndex}
                                        disabled={true}
                                    >
                                        <DataOutput type='Call' style={{padding:2, width:'99%'}}/>
                                        <DataOutput type='Put' style={{padding:2, width:'99%', overflow: 'hidden'}}/>
                                    </SwipeableViews>
                                </Grid>
                            </Grid>
                        </Hidden>

                    </Paper>
                    <Grid item xs={12}>

                    </Grid>
                </Grid>

                <Grid item xs={12}>
                        <div style={{ margin: '16px 16px 16px 16px'}}>
                            <Grid >
                               <Grid item style={{ display: 'flex', justifyContent: 'center'}}>
                                    <Typography variant='caption' style={{ color: '#ffffff', position: 'relative', top: '50%', WebkitTransform: 'translateY(-50%)', transform: 'translatey(-50%)' }}>Powered by</Typography>
                                </Grid> 
                                <Grid item style={{ display: 'flex', justifyContent: 'center'}}>
                                <a href="https://optionenhandeln.ch/" target="_blank" rel="noopener" >
                                    <img src={OH_LOGO} alt='https://optionenhandeln.ch/' style={{ height: 'auto', width: '100px' }} />
                                </a>
                                </Grid>
                                
                            </Grid>
                    </div>
           </Grid>

            </Grid >
        </div>
    )
}


export default MainView